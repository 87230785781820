import * as React from "react"

// markup
const NotFoundPage = () => {
  return (
    <main>
      <h1>404</h1>
      <p class="p-large">Something's wrong here, Emperor.</p>
      <p>If you're lost, here are some helpful pages:</p>
      <ul>
        <li><a href="/getting-started">Getting Started</a></li>
        <li><a href="/foundations">Foundations</a></li>
        <li><a href="/components">Components</a></li>
        <li><a href="/help-feedback">Help &amp; Feedback</a></li>
      </ul>
    </main>
  )
}

export default NotFoundPage
